<script>
import Layout from "@/router/layouts/exchange";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Buy': 'Comprar',
      'Current balance': 'Saldo atual',
      'Which currency you want to buy?': 'Qual moeda você deseja comprar?',
      'How much would you buy?': 'Quanto você deseja comprar?',
      'Price': 'Preço',
      'Tax': 'Taxa',
      'Purchase amount': 'Valor da compra'
    },
    es: {
      'Buy': 'Comprar',
      'Current balance': 'Saldo atual',
      'Which currency you want to buy?': 'Qual moeda você deseja comprar?',
      'How much would you buy?': 'Quanto você deseja comprar?',
      'Price': 'Preço',
      'Tax': 'Taxa',
      'Purchase amount': 'Valor da compra'
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      active: false,

      fiat: {
        balance: '0.00',
      },

      crypto: {
        name: '-',
        price: '0.00',
        tax: '0.00',
      },

      currency: {
        value: 'omc',
        options: {
          omc: 'OMC',
        }
      },

      value: '',

      purchase: {
        amount: '0.00000000'
      },

      loading: false,

      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    value: { required },
  },
  methods: {
    getConfig() {
      api
        .get('exchange/market')
        .then(response => {
          if (response.data.status=='success') {
            this.fiat.balance = response.data.fiat.balance
            this.crypto.name = response.data.crypto.name
            this.crypto.price = response.data.crypto.price
            this.crypto.tax = response.data.crypto.tax
          }
        })
    },
    getPurchaseAmount() {
      api
        .post('exchange/market/purchase', {
          amount: this.value
        })
        .then(response => {
          if (response.data.status=='success') {
            this.purchase.amount = response.data.amount
          }
        })
    },
    createTransaction() {
      this.$v.$touch();

      if (this.value) {
        this.loading = true

        api
          .post('exchange/market', {
            currency: this.currency.value,
            amount: this.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$noty.success(response.data.message)
              this.$router.push('/exchange/wallets')
            } else {
              this.$noty.error(response.data.message)
            }
            this.loading = false
          })
      }
    }
  },
  mounted() {
    this.getConfig()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex">
          <h4 class="mb-0">{{ t('Buy') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between py-2 border-bottom">
              <span>{{ t('Current balance') }}</span>
              <span>{{ fiat.balance | currency }}</span>
            </div>
            <b-form @submit.prevent="createTransactions()" class="pt-4">
              <b-form-group id="currency">
                <label>{{ t('Which currency you want to buy?') }}</label>
                <select v-model="currency.value" id="currency" class="custom-select mb-1">
                  <option v-for="(option,index) in currency.options" :value="index" :key="index">{{option}}</option>
                </select>
              </b-form-group>
              <b-form-group id="value">
                <label>{{ t('How much would you buy?') }}</label>
                <b-form-input id="value" v-model="value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.value.$error }" v-on:blur="getPurchaseAmount()"></b-form-input>
                <div v-if="$v.value.$error" class="invalid-feedback">
                  <span v-if="!$v.value.required">{{ t('Deposit is required.') }}</span>
                </div>
              </b-form-group>
              <div class="pt-2">
                <div class="d-flex justify-content-between py-1">
                  <span>{{ t('Price') }} ({{ crypto.name }})</span>
                  <span>{{ crypto.price | currency }}</span>
                </div>
                <div class="d-flex justify-content-between py-1">
                  <span>{{ t('Tax') }}</span>
                  <span>{{ crypto.tax }}%</span>
                </div>
                <div class="d-flex justify-content-between py-1 text-success">
                  <span>{{ t('Purchase amount') }}</span>
                  <span>{{ crypto.name }} {{ purchase.amount }} </span>
                </div>
              </div>
              <div class="mt-4">
                <b-button :disabled="loading == true || this.value==='0.00'" type="submit" variant="default" class="btn-lg btn-block">
                  {{ t('Buy') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>